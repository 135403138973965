import React from "react";
import { Button, GenericModal, Typography } from "../../../Components";
import { IoClose } from "react-icons/io5";
import { ROUTER_PATH } from "../../../config";
import { useNavigate } from "react-router-dom";

const UpgradeModal = ({ isVisible, setIsVisible }) => {
  const navigate = useNavigate();

  return (
    <GenericModal show={isVisible} onHide={() => setIsVisible(false)}>
      <div className="p-4">
        <div className="d-flex justify-content-end w-100">
          <IoClose
            size={20}
            onClick={() => setIsVisible(false)}
            className="cursor-pointer"
          />
        </div>
        <Typography
          className="mt-3"
          align="center"
          fontWeight="500"
          fontSize="1.2rem"
          // color="#9370DB"
        >
          Hi, You’ve reached the limit of free queries for this week. To
          continue receiving personalised health advice and support, consider
          subscribing to our paid plan.
          {/* Your plan is free. Please update the plan for futhure query thanks! */}
        </Typography>
        <div className="d-flex justify-content-center w-100 mt-4 mb-3">
          <Button
            // backgroundColor="#fff"
            type="button"
            // className="w-100"
            onClick={() => navigate(ROUTER_PATH.SUBSCRIPTION)}
          >
            <Typography
              align="center"
              fontWeight="500"
              fontSize="1rem"
              color="#fff"
            >
              Subscription
            </Typography>
          </Button>
        </div>
      </div>
    </GenericModal>
  );
};

export default UpgradeModal;
