import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Typography } from "../../../../Components";

function MeasurementLb({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  return (
    <div>
      {" "}
      <Row className="d-flex justify-start  px-3">
        <Col md={4} className="p-0 ">
          <Typography fontSize="1.15rem" fontWeight="400" className="my-3">
            Weight (lbs)
          </Typography>
          <Form.Control
            type="text"
            backgroundColor="#EBEBFF"
            name="weightLbs"
            placeholder="e.g. 150"
            className="w-100 fs-6"
            value={values?.weightLbs}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.weightLbs && !!errors.weightLbs}
            size="lg"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            {errors.weightLbs}
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row className="d-flex justify-start     px-3">
        <Col md={4} className="p-0 ">
          <Typography fontSize="1.15rem" fontWeight="400" className="my-3">
            Height (ft/in)
          </Typography>
          <Form.Control
            required
            type="text"
            backgroundColor="#EBEBFF"
            name="heightFt"
            placeholder="e.g. 5.8"
            className="w-100 fs-6"
            value={values?.heightFt}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.heightFt && !!errors.heightFt}
            size="lg"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            {errors.heightFt}
          </Form.Control.Feedback>
        </Col>
      </Row>
    </div>
  );
}

export default MeasurementLb;
