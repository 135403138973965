import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";

export default function PrivateRoutes() {
  const auth = JSON.parse(localStorage.getItem("med_user"));
  const RoleId = auth?.roleId;

  const location = useLocation();
  return auth ? (
    RoleId !== 1 ? (
      <Outlet />
    ) : (
      <Navigate to={ROUTER_PATH.Dashboard} state={{ from: location }} replace />
    )
  ) : (
    <Navigate to={ROUTER_PATH.SIGN_IN} state={{ from: location }} replace />
  );
}
