import {
  generateChatName,
  generateChatReport,
  getAudioFromTextApi,
  getChatApi,
  getChatHistoryApi,
  getDoctorProfileApi,
  getPromptResponseApi,
  getWelcomeMessageApi,
  saveChatApi,
  saveMessageApi,
  saveUserChatApi,
  sessionEndApi,
} from "../api/chat";

export function getWelcomeMessage(language) {
  return (dispatch) => {
    dispatch({
      type: "GET_WELCOME_MESSAGE_REQUEST",
    });
    getWelcomeMessageApi(language).then(
      (response) => {
        // console.log("api response", response.data)
        dispatch({
          type: "GET_WELCOME_MESSAGE_SUCCESS",
          payload: response.data.message,
        });
      },
      (error) => {
        dispatch({
          type: "GET_WELCOME_MESSAGE_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getPromptResponse(payload, OptionalPayload, chat_id, id, file, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "GET_PROMPT_RESPONSE_REQUEST",
    });
    getPromptResponseApi(payload, OptionalPayload, chat_id, id, file).then(
      (response) => {
        dispatch({
          type: "GET_PROMPT_RESPONSE_SUCCESS",
          payload: response.data,
        });

        if (moveToNext) moveToNext(response?.data?.data, payload);
      },
      (error) => {
        dispatch({
          type: "GET_PROMPT_RESPONSE_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function getAudioFromText() {
  return (dispatch) => {
    dispatch({
      type: "GET_AUDIO_FROM_TEXT_REQUEST",
    });

    getAudioFromTextApi().then(
      // getAudioFromTextApi(payload, userId, id).then(
      (response) => {
        let bytes = new Uint8Array(response.data.length);

        for (let i = 0; i < bytes.length; i++) {
          bytes[i] = response.data.charCodeAt(i);
        }
        let blob = new Blob([bytes], { type: "audio/mp3" });

        dispatch({
          type: "GET_AUDIO_FROM_TEXT_SUCCESS",
          payload: blob,
          // payload: response.data,
        });
      },
      (error) => {
        dispatch({
          type: "GET_AUDIO_FROM_TEXT_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function saveUserChat(chatName, user_id, Toaster, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_CHAT_HISTORY_REQUEST",
    });
    saveUserChatApi(chatName, user_id).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: "SAVE_CHAT_HISTORY_SUCCESS",
            payload: response.data,
          });
          if (Toaster)
            Toaster({
              message: `Chat save Successfully`,
              type: "success",
            });
          if (moveToNext) moveToNext();
        }
      },
      (error) => {
        dispatch({
          type: "SAVE_CHAT_HISTORY_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getUserChatHistory(userId) {
  // console.log("user id in action: ", userId);
  return (dispatch) => {
    dispatch({
      type: "GET_CHAT_HISTORY_REQUEST",
    });
    getChatHistoryApi(userId).then(
      (response) => {
        // console.log("get history response: ", response);
        dispatch({
          type: "GET_CHAT_HISTORY_SUCCESS",
          payload: response,
        });
      },
      (error) => {
        dispatch({
          type: "GET_CHAT_HISTORY_IS_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function saveChatAction(payload, moveToNext, reply, userText) {
  
  return (dispatch) => {
    dispatch({
      type: "SAVE_CHAT_REQUEST",
    });
    saveChatApi(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.succeeded) {
          dispatch({
            type: "SAVE_CHAT_SUCCESS",
            payload: response.data,
          });
          if (moveToNext) moveToNext(response, reply, userText);
        }
      },
      (error) => {
        dispatch({
          type: "SAVE_CHAT_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function saveMessage(payload, moveToNextChatReplySave, reply) {

  return (dispatch) => {
    dispatch({
      type: "SAVE_MESSAGE_REQUEST",
    });

    saveMessageApi(payload).then(
      (response) => {
        if (response.status === 200 || response?.succeeded) {
          dispatch({
            type: "SAVE_MESSAGE_SUCCESS",
            payload: response.data,
          });
          // if (Toaster)
          //   Toaster({
          //     message: `Chat save Successfully`,
          //     type: "success",
          //   });

          if (moveToNextChatReplySave) {

            moveToNextChatReplySave({ chatId: payload?.chatId, reply });
          }
        }
      },
      (error) => {
        dispatch({
          type: "SAVE_MESSAGE_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getDoctorProfile(payload) {
  return (dispatch) => {
    dispatch({
      type: "GET_DOCTOR_PROFILE_REQUEST",
    });
    getDoctorProfileApi(payload).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: "GET_DOCTOR_PROFILE_SUCCESS",
            payload: response.data,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_DOCTOR_PROFILE_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function endSessionAction(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "END_SESSION_REQUEST",
    });
    sessionEndApi(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.succeeded) {
          dispatch({
            type: "END_SESSION_SUCCESS",
            payload: response.data,
          });
          if (moveToNext) moveToNext(response);
        } else {
          dispatch({
            type: "END_SESSION_FAILURE",
            payload: response.data,
          });
        }
      },
      (error) => {
        dispatch({
          type: "END_SESSION_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function generateChatNameAction(payload) {
  return (dispatch) => {
    dispatch({
      type: "GENERATE_CHAT_NAME_REQUEST",
    });
    generateChatName(payload).then(
      (response) => {
        if (response?.data?.succeeded) {
          dispatch({
            type: "GENERATE_CHAT_NAME_SUCCESS",
            payload: response.data,
          });

        } else {
          dispatch({
            type: "GENERATE_CHAT_NAME_FAILURE",
            payload: response.data,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GENERATE_CHAT_NAME_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function generateChatReportAction(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "GENERATE_CHAT_REPORT_REQUEST",
    });
    generateChatReport(payload).then(
      (response) => {
        if (response?.data?.succeeded) {
          dispatch({
            type: "GENERATE_CHAT_REPORT_SUCCESS",
            payload: response.data.data,
          });
          if (moveToNext) moveToNext(response);
        } else {
          dispatch({
            type: "GENERATE_CHAT_REPORT_FAILURE",
            payload: response.data,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GENERATE_CHAT_REPORT_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

// ----------------------------------------------

export function getChatByUsers(payload, getImageCount) {
  // console.log("user id in action: ", payload);
  return (dispatch) => {
    dispatch({
      type: "GET_CHAT_HISTORY_REQUEST",
    });
    getChatApi(payload).then(
      (response) => {
        // console.log("get history response: ", response);
        dispatch({
          type: "GET_CHAT_HISTORY_SUCCESS",
          payload: response,
        });
        if (getImageCount) getImageCount(response)
      },
      (error) => {
        dispatch({
          type: "GET_CHAT_HISTORY_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
