import { API_URLS, api } from "../../config";

export function createSubscriptionApi(data) {
  return api.post(`${API_URLS.SUBSCRIPTION.CREATE}`, data);
}
export function UpdateSubscriptionApi(data) {
  return api.patch(`${API_URLS.SUBSCRIPTION.UPDATE_SUBSCRIPTION}`, data);
}

export function checkSubscriptionApi(userId) {
  return api.put(`${API_URLS.SUBSCRIPTION.CHECK + userId}`);
}

export function getAllPlan(roleId) {
  return api.get(`${API_URLS.SUBSCRIPTION.GET_ALL_PLAN}`);
  // return api.get(`${API_URLS.SUBSCRIPTION.GET_ALL_PLAN}?roleId=${roleId}`);
}

export function cancelUserSubscriptions(id) {
  return api.put(`${API_URLS.SUBSCRIPTION.CANCEL_SUBSCRIPTION + id}`);
}
