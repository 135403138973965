import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";

export default function PublicRoutes() {
  const auth = localStorage.getItem("med_user") ? true : false;
  const location = useLocation();

  return auth ? (
    <Navigate to={ROUTER_PATH.HOME_PAGE} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
}
