import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import ChartCardWithDropdown from "./ChartCardWithDropdown";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDashboardSubscriptionGraph } from "../../../Redux/actions/DashboardActions";

const SubscriptionGraph = () => {
  let dispatch = useDispatch();
  const dashboard = useSelector((state) => state.AdminDashboard);
  const subscriptionGraphData = dashboard?.GET_DASHBOARD_SUBSCRIPTION_LIST;

  const [selectedFilter, setSelectedFilter] = useState(3);
  const [seriesData, setSeriesData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const timeAnalysis = {
    series: [
      {
        name: "quantity",
        data: seriesData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          tools: {
            download: false, // Disable the download menu
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
  };

  const options = [
    { value: 3, label: "Weekly" },
    { value: 2, label: "Monthly" },
    { value: 1, label: "Yearly" },
  ];

  const planOptions = [
    { value: 2, label: "Monthly Premium" },
    { value: 3, label: "Monthly Professional" },
    { value: 4, label: "Yearly Premium" },
    { value: 5, label: "Yearly Professional" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(
          getAdminDashboardSubscriptionGraph(selectedFilter, selectedPlan)
        );
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedFilter, selectedPlan, dispatch]);

  useEffect(() => {
    if (subscriptionGraphData) {
      const newSeriesData = subscriptionGraphData.map((item) => item.count);
      const newCategories = subscriptionGraphData.map((item) => item.key);
      setSeriesData(newSeriesData);
      setCategories(newCategories);
    }
  }, [subscriptionGraphData]);

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
  };
  const handlePlanChange = (e) => {
    setSelectedPlan(parseInt(e.target.value));
  };

  return (
    <div className="my-5">
      <ChartCardWithDropdown
        options={options}
        planOptions={planOptions}
        selectedOption={selectedFilter}
        selectedPlan={selectedPlan}
        onFilterChange={handleFilterChange}
        onPlanChange={handlePlanChange}
        title="Subscriptions"
      >
        {loading || dashboard?.GET_DASHBOARD_SUBSCRIPTION_LIST_LOADING ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 350 }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <ReactApexChart
            options={timeAnalysis.options}
            series={timeAnalysis.series}
            type="bar"
            height={350}
          />
        )}
      </ChartCardWithDropdown>
    </div>
  );
};

export default SubscriptionGraph;
