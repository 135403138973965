const INITIAL_STATES = {
    GET_DASHBOARD_CARDS_STATS_LIST_LOADING: false,
    GET_DASHBOARD_CARDS_STATS_LIST_SUCCESS: false,
    GET_DASHBOARD_CARDS_STATS_LIST_FAILURE: false,
    GET_DASHBOARD_CARDS_STATS_LIST_ERROR: null,
    GET_DASHBOARD_CARDS_STATS_LIST: null,


    GET_DASHBOARD_PATIENT_GRAPH_LIST_LOADING: false,
    GET_DASHBOARD_PATIENT_GRAPH_LIST_SUCCESS: false,
    GET_DASHBOARD_PATIENT_GRAPH_LIST_FAILURE: false,
    GET_DASHBOARD_PATIENT_GRAPH_LIST_ERROR: null,
    GET_DASHBOARD_PATIENT_GRAPH_LIST: null,


    GET_DASHBOARD_SUBSCRIPTION_LIST_LOADING: false,
    GET_DASHBOARD_SUBSCRIPTION_LIST_SUCCESS: false,
    GET_DASHBOARD_SUBSCRIPTION_LIST_FAILURE: false,
    GET_DASHBOARD_SUBSCRIPTION_LIST_ERROR: null,
    GET_DASHBOARD_SUBSCRIPTION_LIST: null,





  }
export function DashboardReducer(state = INITIAL_STATES, action) {
    switch (action.type) {
     
      case "GET_DASHBOARD_CARDS_STATS_LIST_REQUEST":
        return {
          ...state,
          GET_DASHBOARD_CARDS_STATS_LIST_LOADING: true,
          GET_DASHBOARD_CARDS_STATS_LIST_SUCCESS: false,
          GET_DASHBOARD_CARDS_STATS_LIST_FAILURE: false,
          GET_DASHBOARD_CARDS_STATS_LIST_ERROR: null,
        };
        case "GET_DASHBOARD_CARDS_STATS_LIST_SUCCESS":
            return {
              ...state,
              GET_DASHBOARD_CARDS_STATS_LIST_LOADING: false,
              GET_DASHBOARD_CARDS_STATS_LIST_SUCCESS: true,
              GET_DASHBOARD_CARDS_STATS_LIST_FAILURE: false,
              GET_DASHBOARD_CARDS_STATS_LIST_ERROR: null,
              GET_DASHBOARD_CARDS_STATS_LIST: action.payload,
            };
          case "GET_DASHBOARD_CARDS_STATS_LIST_FAILURE":
            return {
              ...state,
              GET_DASHBOARD_CARDS_STATS_LIST_LOADING: false,
              GET_DASHBOARD_CARDS_STATS_LIST_SUCCESS: false,
              GET_DASHBOARD_CARDS_STATS_LIST_FAILURE: true,
              GET_DASHBOARD_CARDS_STATS_LIST_ERROR: action.payload,
            };
      


            case "GET_DASHBOARD_PATIENT_GRAPH_LIST_REQUEST":
              return {
                ...state,
                GET_DASHBOARD_PATIENT_GRAPH_LIST_LOADING: true,
                GET_DASHBOARD_PATIENT_GRAPH_LIST_SUCCESS: false,
                GET_DASHBOARD_PATIENT_GRAPH_LIST_FAILURE: false,
                GET_DASHBOARD_PATIENT_GRAPH_LIST_ERROR: null,
              };
              case "GET_DASHBOARD_PATIENT_GRAPH_LIST_SUCCESS":
                  return {
                    ...state,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_LOADING: false,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_SUCCESS: true,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_FAILURE: false,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_ERROR: null,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST: action.payload,
                  };
                case "GET_DASHBOARD_PATIENT_GRAPH_LIST_FAILURE":
                  return {
                    ...state,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_LOADING: false,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_SUCCESS: false,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_FAILURE: true,
                    GET_DASHBOARD_PATIENT_GRAPH_LIST_ERROR: action.payload,
                  };
            

                  
            case "GET_DASHBOARD_SUBSCRIPTION_LIST_REQUEST":
              return {
                ...state,
                GET_DASHBOARD_SUBSCRIPTION_LIST_LOADING: true,
                GET_DASHBOARD_SUBSCRIPTION_LIST_SUCCESS: false,
                GET_DASHBOARD_SUBSCRIPTION_LIST_FAILURE: false,
                GET_DASHBOARD_SUBSCRIPTION_LIST_ERROR: null,
              };
              case "GET_DASHBOARD_SUBSCRIPTION_LIST_SUCCESS":
                  return {
                    ...state,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_LOADING: false,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_SUCCESS: true,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_FAILURE: false,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_ERROR: null,
                    GET_DASHBOARD_SUBSCRIPTION_LIST: action.payload,
                  };
                case "GET_DASHBOARD_SUBSCRIPTION_LIST_FAILURE":
                  return {
                    ...state,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_LOADING: false,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_SUCCESS: false,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_FAILURE: true,
                    GET_DASHBOARD_SUBSCRIPTION_LIST_ERROR: action.payload,
                  };
            
 
            default:
            return state;
        }
      }
      