import { API_URLS, api } from "../../config";

async function saveMessageApi(data) {
  const res = await api.post(`${API_URLS.MESSAGE.SAVE}`, data);
  return res?.data;
}

function getMessageApi(chatId) {
  return api.get(
    `${API_URLS.MESSAGE.GET}?chatId=${chatId}&isTitleMessage=${false}`
    // `${API_URLS.MESSAGE.GET}?fromUserId=${data?.fromUserId}&toUserId=${data?.toUserId}`
  );
}

export { saveMessageApi, getMessageApi };
