import React, { useState } from "react";
import { Typography } from "../../../../Components";
import { Col, Form, Row } from "react-bootstrap";
import { LoadScript } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { API_KEYS } from "../../../../config";

const Age = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const focusStyle = isFocused
    ? {
        borderColor: "black", // Change this to your desired color
        // boxShadow: "0 0 0 0.2rem rgba(35, 22, 113, 0.25)", // Adjust the color and transparency as needed
      }
    : {};

  return (
    <div>
      <Typography fontSize="1rem" fontWeight="400" color="#9370DB">
        GENERAL INFORMATION
      </Typography>

      <Row className="d-flex justify-start mt-4">
        <Col sm={12} md={6} className=" mb-3 ">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            required
            type="text"
            backgroundColor="#EBEBFF"
            name="name"
            placeholder="Enter Full Name"
            className="w-100 fs-6"
            value={values?.name}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              setIsFocused(false);
            }}
            isInvalid={touched.name && !!errors.name}
            size="lg"
            style={focusStyle}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Col>
        <Col sm={12} md={6} className=" mb-3 ">
          <Form.Label>Contact No.</Form.Label>
          <Form.Control
            required
            type="text"
            backgroundColor="#EBEBFF"
            name="mobileContact"
            placeholder="+1 (555) 1234-567"
            className="w-100 fs-6"
            value={values?.mobileContact}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              setIsFocused(false);
            }}
            isInvalid={touched.mobileContact && !!errors.mobileContact}
            size="lg"
            style={focusStyle}
          />
          <Form.Control.Feedback type="invalid">
            {errors.mobileContact}
          </Form.Control.Feedback>
        </Col>

        <Col sm={12} md={6} className=" mb-3 ">
          <Form.Label>Age</Form.Label>
          <Form.Control
            required
            type="number"
            backgroundColor="#EBEBFF"
            name="age"
            placeholder="Enter Age"
            className="w-100 fs-6"
            value={values?.age}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              setIsFocused(false);
            }}
            isInvalid={touched.age && !!errors.age}
            size="lg"
            style={focusStyle}
          />
          <Form.Control.Feedback type="invalid">
            {errors.age}
          </Form.Control.Feedback>
        </Col>
        <Col sm={12} md={6} className=" mb-3 ">
          <Form.Group controlId="ageUnit">
            <Form.Label>Select</Form.Label>
            <Form.Select
              size="lg"
              name="ageUnit"
              value={values.ageUnit}
              onChange={handleChange}
              onBlur={handleBlur}
              className="fs-6"
            >
              <option value="years">Years</option>
              <option value="months">Months</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col sm={12} md={12} className=" mb-3 ">
          <Form.Label>Occupation</Form.Label>
          <Form.Control
            required
            type="text"
            backgroundColor="#EBEBFF"
            name="occupation"
            placeholder="Enter Occupation"
            className="w-100 fs-6"
            value={values?.occupation}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              setIsFocused(false);
            }}
            isInvalid={touched.occupation && !!errors.occupation}
            size="lg"
            style={focusStyle}
          />
          <Form.Control.Feedback type="invalid">
            {errors.occupation}
          </Form.Control.Feedback>
        </Col>

        <Col sm={12} className="mb-3">
          <Form.Label>Location</Form.Label>
          <LoadScript
            googleMapsApiKey={API_KEYS.GOOGLE_MAP_API_KEY}
            libraries={["places"]}
          >
            <Autocomplete
              style={{
                border: "1px solid #dee2e6",
                padding: "10px",
                borderRadius: "8px",
              }}
              apiKey={API_KEYS.GOOGLE_MAP_API_KEY}
              onPlaceSelected={(place) => {
                const selectedAddress = place.formatted_address;
                setFieldValue("address", selectedAddress);
              }}
              options={{
                types: ["address"],
              }}
              className="w-100 fs-6"
              placeholder="Enter Address"
              value={values?.address}
              onChange={(e) => {
                setFieldValue("address", e.target.value);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setIsFocused(false);
              }}
            />
          </LoadScript>
        </Col>
      </Row>
    </div>
  );
};

export default Age;
