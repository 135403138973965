const INITIAL_STATES = {
  GET_WELCOME_MESSAGE_IS_LOADING: false,
  GET_WELCOME_MESSAGE_IS_SUCCESS: false,
  GET_WELCOME_MESSAGE_IS_FALURE: false,
  WELCOME_MESSAGE: null,
  GET_WELCOME_MESSAGE_IS_ERROR: null,

  GET_PROMPT_RESPONSE_IS_LOADING: false,
  GET_PROMPT_RESPONSE_IS_SUCCESS: false,
  GET_PROMPT_RESPONSE_IS_FAILURE: false,
  PROMPT_RESPONSE: [],
  GET_PROMPT_RESPONSE_IS_ERROR: null,

  SAVE_CHAT_HISTORY_IS_LOADING: false,
  SAVE_CHAT_HISTORY_IS_SUCCESS: false,
  SAVE_CHAT_HISTORY_IS_FAILURE: false,
  SAVE_CHAT_HISTORY_IS_ERROR: null,

  GET_CHAT_HISTORY_IS_LOADING: false,
  GET_CHAT_HISTORY_IS_SUCCESS: false,
  GET_CHAT_HISTORY_IS_FAILUER: false,
  CHAT: [],
  TOTAL_MESSAGES: null,
  GET_CHAT_HISTORY_IS_ERROR: null,

  SAVE_MESSAGE_LOADING: false,
  SAVE_MESSAGE_SUCCESS: false,
  SAVE_MESSAGE_FAILURE: false,
  MESSAGE: [],
  SAVE_MESSAGE_ERROR: null,

  SAVE_CHAT_LOADING: false,
  SAVE_CHAT_SUCCESS: false,
  SAVE_CHAT_FAILUER: false,
  SAVE_CHAT_ERROR: null,

  GET_DOCTOR_PROFILE_LOADING: false,
  GET_DOCTOR_PROFILE_SUCCESS: false,
  GET_DOCTOR_PROFILE_FALURE: false,
  DOCTOR_PROFILE: null,
  GET_DOCTOR_PROFILE_ERROR: null,

  END_SESSION_LOADING: false,
  END_SESSION_SUCCESS: false,
  END_SESSION_FAILURE: false,
  END_SESSION_ERROR: null,

  GENERATE_CHAT_REPORT_LOADING: false,
  GENERATE_CHAT_REPORT_SUCCESS: false,
  GENERATE_CHAT_REPORT_FAILURE: false,
  GENERATE_CHAT_REPORT_ERROR: null,
  GENERATE_CHAT_REPORT: null,

  GET_IMAGE_COUNT_LOADING: false,
  GET_IMAGE_COUNT_SUCCESS: false,
  GET_IMAGE_COUNT_FAILURE: false,
  GET_IMAGE_COUNT: null,
  GET_IMAGE_COUNT_ERROR: null,
};

export function chatReducer(state = INITIAL_STATES, action) {
  switch (action.type) {
    case "GET_WELCOME_MESSAGE_REQUEST":
      return {
        ...state,
        GET_WELCOME_MESSAGE_IS_LOADING: true,
        GET_WELCOME_MESSAGE_IS_SUCCESS: false,
        GET_WELCOME_MESSAGE_IS_FALURE: false,
        WELCOME_MESSAGE: null,
        GET_WELCOME_MESSAGE_IS_ERROR: null,
      };
    case "GET_WELCOME_MESSAGE_SUCCESS":
      return {
        ...state,
        GET_WELCOME_MESSAGE_IS_LOADING: false,
        GET_WELCOME_MESSAGE_IS_SUCCESS: true,
        GET_WELCOME_MESSAGE_IS_FALURE: false,
        WELCOME_MESSAGE: action.payload,
        GET_WELCOME_MESSAGE_IS_ERROR: null,
      };
    case "GET_WELCOME_MESSAGE_FAILURE":
      return {
        ...state,
        GET_WELCOME_MESSAGE_IS_LOADING: false,
        GET_WELCOME_MESSAGE_IS_SUCCESS: false,
        GET_WELCOME_MESSAGE_IS_FALURE: true,
        WELCOME_MESSAGE: null,
        GET_WELCOME_MESSAGE_IS_ERROR: action.payload,
      };

    case "GET_PROMPT_RESPONSE_REQUEST":
      return {
        ...state,
        GET_PROMPT_RESPONSE_IS_LOADING: true,
        GET_PROMPT_RESPONSE_IS_SUCCESS: false,
        GET_PROMPT_RESPONSE_IS_FAILURE: false,
        PROMPT_RESPONSE: null,
        GET_PROMPT_RESPONSE_IS_ERROR: null,
      };
    case "GET_PROMPT_RESPONSE_SUCCESS":
      return {
        ...state,
        GET_PROMPT_RESPONSE_IS_LOADING: false,
        GET_PROMPT_RESPONSE_IS_SUCCESS: true,
        GET_PROMPT_RESPONSE_IS_FAILURE: false,
        PROMPT_RESPONSE: action.payload,
        GET_PROMPT_RESPONSE_IS_ERROR: null,
      };
    case "GET_PROMPT_RESPONSE_ERROR":
      return {
        ...state,
        GET_PROMPT_RESPONSE_IS_LOADING: false,
        GET_PROMPT_RESPONSE_IS_SUCCESS: false,
        GET_PROMPT_RESPONSE_IS_FAILURE: true,
        PROMPT_RESPONSE: null,
        GET_PROMPT_RESPONSE_IS_ERROR: action.payload,
      };

    case "GET_AUDIO_FROM_TEXT_REQUEST":
      return {
        ...state,
        GET_AUDIO_FROM_TEXT_IS_LOADING: true,
        GET_AUDIO_FROM_TEXT_IS_SUCCESS: false,
        GET_AUDIO_FROM_TEXT_IS_FAILURE: false,
        AUDIO_FROM_TEXT: null,
        GET_AUDIO_FROM_TEXT_IS_ERROR: null,
      };
    case "GET_AUDIO_FROM_TEXT_SUCCESS":
      return {
        ...state,
        GET_AUDIO_FROM_TEXT_IS_LOADING: false,
        GET_AUDIO_FROM_TEXT_IS_SUCCESS: true,
        GET_AUDIO_FROM_TEXT_IS_FAILURE: false,
        AUDIO_FROM_TEXT: action.payload,
        GET_AUDIO_FROM_TEXT_IS_ERROR: null,
      };
    case "GET_AUDIO_FROM_TEXT_ERROR":
      return {
        ...state,
        GET_AUDIO_FROM_TEXT_IS_LOADING: false,
        GET_AUDIO_FROM_TEXT_IS_SUCCESS: false,
        GET_AUDIO_FROM_TEXT_IS_FAILURE: true,
        AUDIO_FROM_TEXT: null,
        GET_AUDIO_FROM_TEXT_IS_ERROR: action.payload,
      };

    case "SAVE_CHAT_HISTORY_REQUEST":
      return {
        ...state,
        SAVE_CHAT_HISTORY_IS_LOADING: true,
        SAVE_CHAT_HISTORY_IS_SUCCESS: false,
        SAVE_CHAT_HISTORY_IS_FAILURE: false,
        SAVE_CHAT_HISTORY_IS_ERROR: null,
      };
    case "SAVE_CHAT_HISTORY_SUCCESS":
      return {
        ...state,
        SAVE_CHAT_HISTORY_IS_LOADING: false,
        SAVE_CHAT_HISTORY_IS_SUCCESS: true,
        SAVE_CHAT_HISTORY_IS_FAILURE: false,
        SAVE_CHAT_HISTORY_IS_ERROR: null,
      };
    case "SAVE_CHAT_HISTORY_FAILURE":
      return {
        ...state,
        SAVE_CHAT_HISTORY_IS_LODAING: false,
        SAVE_CHAT_HISTORY_IS_SUCCESS: false,
        SAVE_CHAT_HISTORY_IS_FAILURE: true,
        SAVE_CHAT_HISTORY_IS_ERROR: action.payload,
      };

    case "GET_CHAT_HISTORY_REQUEST":
      return {
        ...state,
        GET_CHAT_HISTORY_IS_LOADING: true,
        GET_CHAT_HISTORY_IS_SUCCESS: false,
        GET_CHAT_HISTORY_IS_FAILURE: false,
        CHAT: [],
        TOTAL_MESSAGES: null,
        GET_CHAT_HISTORY_IS_ERROR: null,
      };
    case "GET_CHAT_HISTORY_SUCCESS":
      return {
        ...state,
        GET_CHAT_HISTORY_IS_LOADING: false,
        GET_CHAT_HISTORY_IS_SUCCESS: true,
        GET_CHAT_HISTORY_IS_FAILURE: false,
        CHAT: action.payload?.data,
        TOTAL_MESSAGES: action.payload?.totalMessageCounts,
        GET_CHAT_HISTORY_IS_ERROR: null,
      };
    case "GET_CHAT_HISTORY_IS_FAILURE":
      return {
        ...state,
        GET_CHAT_HISTORY_IS_LOADING: false,
        GET_CHAT_HISTORY_IS_SUCCESS: false,
        GET_CHAT_HISTORY_IS_FAILURE: true,
        CHAT: [],
        TOTAL_MESSAGES: null,
        GET_CHAT_HISTORY_IS_ERROR: action.payload,
      };

    // --------Save Message----------
    case "SAVE_MESSAGE_REQUEST":
      return {
        ...state,
        SAVE_MESSAGE_LOADING: true,
        SAVE_MESSAGE_SUCCESS: false,
        SAVE_MESSAGE_FAILURE: false,
        SAVE_MESSAGE_ERROR: null,
      };
    case "SAVE_MESSAGE_SUCCESS":
      return {
        ...state,
        SAVE_MESSAGE_LOADING: false,
        SAVE_MESSAGE_SUCCESS: true,
        SAVE_MESSAGE_FAILURE: false,
        MESSAGE: action.payload,
        // CHAT: state?.CHAT?.map((chat) => {
        //   if (chat?.chatId === action?.payload?.chatId) {
        //     return {
        //       ...chat,
        //       messages: [...chat?.messages, ...action?.payload],
        //     };
        //   } else return chat;
        // }),
        SAVE_MESSAGE_ERROR: null,
      };
    case "SAVE_MESSAGE_FAILURE":
      return {
        ...state,
        SAVE_MESSAGE_LODAING: false,
        SAVE_MESSAGE_SUCCESS: false,
        SAVE_MESSAGE_FAILURE: true,
        SAVE_MESSAGE_ERROR: action.payload,
      };

    // ---------------------------------------------------

    case "SAVE_CHAT_REQUEST":
      return {
        ...state,
        SAVE_CHAT_LOADING: true,
        SAVE_CHAT_SUCCESS: false,
        SAVE_CHAT_FAILURE: false,
        SAVE_CHAT_ERROR: null,
      };
    case "SAVE_CHAT_SUCCESS":
      return {
        ...state,
        SAVE_CHAT_LOADING: false,
        SAVE_CHAT_SUCCESS: true,
        SAVE_CHAT_FAILURE: false,
        SAVE_CHAT_ERROR: null,
      };
    case "SAVE_CHAT_FAILURE":
      return {
        ...state,
        SAVE_CHAT_LODAING: false,
        SAVE_CHAT_SUCCESS: false,
        SAVE_CHAT_FAILURE: true,
        SAVE_CHAT_ERROR: action.payload,
      };

    // -------------------------------

    case "GET_DOCTOR_PROFILE_REQUEST":
      return {
        ...state,
        GET_DOCTOR_PROFILE_LOADING: true,
        GET_DOCTOR_PROFILE_SUCCESS: false,
        GET_DOCTOR_PROFILE_FALURE: false,
        DOCTOR_PROFILE: null,
        GET_DOCTOR_PROFILE_ERROR: null,
      };
    case "GET_DOCTOR_PROFILE_SUCCESS":
      return {
        ...state,
        GET_DOCTOR_PROFILE_LOADING: false,
        GET_DOCTOR_PROFILE_SUCCESS: true,
        GET_DOCTOR_PROFILE_FALURE: false,
        DOCTOR_PROFILE: action.payload,
        GET_DOCTOR_PROFILE_ERROR: null,
      };
    case "GET_DOCTOR_PROFILE_FAILURE":
      return {
        ...state,
        GET_DOCTOR_PROFILE_LOADING: false,
        GET_DOCTOR_PROFILE_SUCCESS: false,
        GET_DOCTOR_PROFILE_FALURE: true,
        DOCTOR_PROFILE: null,
        GET_DOCTOR_PROFILE_ERROR: action.payload,
      };

    // -------------------------------------

    case "END_SESSION_REQUEST":
      return {
        ...state,
        END_SESSION_LOADING: true,
        END_SESSION_SUCCESS: false,
        END_SESSION_FAILURE: false,
        END_SESSION_ERROR: null,
      };
    case "END_SESSION_SUCCESS":
      return {
        ...state,
        END_SESSION_LOADING: false,
        END_SESSION_SUCCESS: true,
        END_SESSION_FAILURE: false,
        END_SESSION_ERROR: null,
        MESSAGE: null,
      };
    case "END_SESSION_FAILURE":
      return {
        ...state,
        END_SESSION_LODAING: false,
        END_SESSION_SUCCESS: false,
        END_SESSION_FAILURE: true,
        END_SESSION_ERROR: action.payload,
      };

    case "GENERATE_CHAT_REPORT_REQUEST":
      return {
        ...state,
        GENERATE_CHAT_REPORT_LOADING: true,
        GENERATE_CHAT_REPORT_SUCCESS: false,
        GENERATE_CHAT_REPORT_FAILURE: false,
        GENERATE_CHAT_REPORT_ERROR: null,
      };
    case "GENERATE_CHAT_REPORT_SUCCESS":
      return {
        ...state,
        GENERATE_CHAT_REPORT_LOADING: false,
        GENERATE_CHAT_REPORT_SUCCESS: true,
        GENERATE_CHAT_REPORT_FAILURE: false,
        GENERATE_CHAT_REPORT_ERROR: null,
        GENERATE_CHAT_REPORT: action.payload,
      };
    case "GENERATE_CHAT_REPORT_FAILURE":
      return {
        ...state,
        GENERATE_CHAT_REPORT_LODAING: false,
        GENERATE_CHAT_REPORT_SUCCESS: false,
        GENERATE_CHAT_REPORT_FAILURE: true,
        GENERATE_CHAT_REPORT_ERROR: action.payload,
      };
    case "RESET_GENERATE_CHAT_REPORT":
      return {
        ...state,
        GENERATE_CHAT_REPORT: null,
        GENERATE_CHAT_REPORT_LOADING: false,
        GENERATE_CHAT_REPORT_SUCCESS: false,
        GENERATE_CHAT_REPORT_FAILURE: false,
        GENERATE_CHAT_REPORT_ERROR: null,
      };

    case "GET_IMAGE_COUNT_REQUEST":
      return {
        ...state,
        GET_IMAGE_COUNT_LOADING: true,
        GET_IMAGE_COUNT_SUCCESS: false,
        GET_IMAGE_COUNT_FAILURE: false,
        GET_IMAGE_COUNT_ERROR: null,
      };
    case "GET_IMAGE_COUNT_SUCCESS":
      return {
        ...state,
        GET_IMAGE_COUNT_LOADING: false,
        GET_IMAGE_COUNT_SUCCESS: true,
        GET_IMAGE_COUNT_FAILURE: false,
        GET_IMAGE_COUNT_ERROR: null,
        GET_IMAGE_COUNT: action.payload,
      };
    case "GET_IMAGE_COUNT_FAILURE":
      return {
        ...state,
        GET_IMAGE_COUNT_LODAING: false,
        GET_IMAGE_COUNT_SUCCESS: false,
        GET_IMAGE_COUNT_FAILURE: true,
        GET_IMAGE_COUNT_ERROR: action.payload,
      };
    case "RESET_GET_IMAGE_COUNT":
      return {
        ...state,
        GET_IMAGE_COUNT: null,
        GET_IMAGE_COUNT_LOADING: false,
        GET_IMAGE_COUNT_SUCCESS: false,
        GET_IMAGE_COUNT_FAILURE: false,
        GET_IMAGE_COUNT_ERROR: null,
      };

    default:
      return state;
  }
}
