/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper, styled } from "@mui/material";
import React, { useEffect } from "react";
import PatientRegisteredGraph from "./PatientRegisteredGraph";
import { Col, Row, Spinner } from "react-bootstrap";
import SubscriptionGraph from "./SubscriptionGraph";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDashboardStats } from "../../../Redux/actions/DashboardActions";

const Index = () => {
  const dashboard = useSelector((state) => state.AdminDashboard);
  console.log("dashboard", dashboard?.GET_DASHBOARD_CARDS_STATS_LIST);
  const statsData = dashboard?.GET_DASHBOARD_CARDS_STATS_LIST;

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: "#9370DB",
    ...theme.typography.body2,
    padding: "10px 20px",
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    fontWeight: 400,
    minHeight: "100px",
  }));
  let dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAdminDashboardStats());
    };

    fetchData();
  }, []);

  return (
    <div className="mt-2">
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Item>
              <span className="fs-6 mb-3 text-black ">Total Users</span>
              <span className="text-primary">
                {dashboard?.GET_DASHBOARD_CARDS_STATS_LIST_LOADING ? (
                  <Spinner className="border-2" />
                ) : (
                  statsData?.totalUserRegistered
                )}
              </span>
            </Item>
          </Grid>
          <Grid item xs={6} md={3}>
            <Item>
              <span className="fs-6 mb-3 text-black">Total Patients</span>
              <span className="text-primary">
                {dashboard?.GET_DASHBOARD_CARDS_STATS_LIST_LOADING ? (
                  <Spinner className="border-2" />
                ) : (
                  statsData?.totalPatientRegistered
                )}
              </span>
            </Item>
          </Grid>

          <Grid item xs={6} md={3}>
            <Item>
              <span className="fs-6 mb-3 text-black ">Total Revenue</span>
              <span className="text-primary ">
                {dashboard?.GET_DASHBOARD_CARDS_STATS_LIST_LOADING ? (
                  <Spinner className="border-2" />
                ) : (
                  `$${statsData?.totalAmountSubscription}`
                )}
              </span>
            </Item>
          </Grid>
          <Grid item xs={6} md={3}>
            <Item>
              <span className="fs-6 mb-3 text-black ">
                Generated Chat Reports
              </span>
              <span className="text-primary">
                {dashboard?.GET_DASHBOARD_CARDS_STATS_LIST_LOADING ? (
                  <Spinner className="border-2" />
                ) : (
                  statsData?.chatReportGenerated
                )}
              </span>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Row>
        <Col md={12} lg={6}>
          <PatientRegisteredGraph className="mt-3" />
        </Col>
        <Col md={12} lg={6}>
          <SubscriptionGraph className="mt-3" />
        </Col>
      </Row>

      {/* <SubscriptionGrapgh /> */}
    </div>
  );
};

export default Index;
