import axios from "axios";
import { API_URLS } from "../../config";


function getAdminDashboardCards() {
    return axios.get(`${API_URLS.ADMIN_DASHBOARD.GET}`);
  }
function getAdminDashboardPatientGraphApi(id) {
    return axios.get(`${API_URLS.ADMIN_DASHBOARD.GET_PATIENT_GRAPH}?durationType=${id}`);
  }
function getAdminDashboardSubscriptionGraphApi(durationId, planId) {

  let url = `${API_URLS.ADMIN_DASHBOARD.GET_SUBSCRIPTION_GRAPH}?durationType=${durationId}`;
  
  if (planId) {
    url += `&plan=${planId}`;
  }
  
  return axios.get(url);
  }


  export {
    getAdminDashboardCards,
    getAdminDashboardPatientGraphApi,
    getAdminDashboardSubscriptionGraphApi
  };