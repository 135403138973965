const INITIAL_STATES = {
  // SAVE_MESSAGE_LOADING: false,
  // SAVE_MESSAGE_SUCCESS: false,
  // SAVE_MESSAGE_FAILURE: false,
  // SAVE_MESSAGE_ERROR: null,

  GET_MESSAGE_LOADING: false,
  GET_MESSAGE_SUCCESS: false,
  GET_MESSAGE_FALURE: false,
  // MESSAGE: null,
  GET_MESSAGE_ERROR: null,
};

export function messageReducer(state = INITIAL_STATES, action) {
  switch (action.type) {
    // --------Save Message----------
    // case "SAVE_MESSAGE_REQUEST":
    //   return {
    //     ...state,
    //     SAVE_MESSAGE_LOADING: true,
    //     SAVE_MESSAGE_SUCCESS: false,
    //     SAVE_MESSAGE_FAILURE: false,
    //     SAVE_MESSAGE_ERROR: null,
    //   };
    // case "SAVE_MESSAGE_SUCCESS":
    //   return {
    //     ...state,
    //     SAVE_MESSAGE_LOADING: false,
    //     SAVE_MESSAGE_SUCCESS: true,
    //     SAVE_MESSAGE_FAILURE: false,
    //     SAVE_MESSAGE_ERROR: null,
    //   };
    // case "SAVE_MESSAGE_FAILURE":
    //   return {
    //     ...state,
    //     SAVE_MESSAGE_LODAING: false,
    //     SAVE_MESSAGE_SUCCESS: false,
    //     SAVE_MESSAGE_FAILURE: true,
    //     SAVE_MESSAGE_ERROR: action.payload,
    //   };

    // --------Get Message----------

    case "GET_MESSAGE_REQUEST":
      return {
        ...state,
        GET_MESSAGE_LOADING: true,
        GET_MESSAGE_SUCCESS: false,
        GET_MESSAGE_FALURE: false,
        GET_MESSAGE_ERROR: null,
      };
    case "GET_MESSAGE_SUCCESS":
      return {
        ...state,
        GET_MESSAGE_LOADING: false,
        GET_MESSAGE_SUCCESS: true,
        GET_MESSAGE_FALURE: false,
        MESSAGE: action.payload,
        GET_MESSAGE_ERROR: null,
      };
    case "GET_MESSAGE_FAILURE":
      return {
        ...state,
        GET_MESSAGE_LOADING: false,
        GET_MESSAGE_SUCCESS: false,
        GET_MESSAGE_FALURE: true,
        MESSAGE: null,
        GET_MESSAGE_ERROR: action.payload,
      };

    default:
      return state;
  }
}
