/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { GenericLoader } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import VerifyEmail from "./VerifyEmail";
import FirstThingFirst from "./FirstThingFirst";
import { verifyAccountAction } from "../../Redux/actions";
import { useLocation } from "react-router-dom";

const Welcome = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isWelcome, setIsWelcome] = useState(true);
  const query = new URLSearchParams(location.search);
  const socialData = location.state;
  const accountCode = JSON.parse(decodeURIComponent(query.get("user")));
  const UserData = useSelector((state) => state?.users);

  function saveUserData(data) {
    return {
      type: "VERIFY_ACCOUNT_SUCCESS",
      payload: data,
    };
  }

  useEffect(() => {
    if (accountCode && !UserData?.VERIFY_ACCOUNT_DATA) {
      setIsWelcome(false);
      dispatch(
        verifyAccountAction({
          accountCode,
        })
      );
    }
    if (socialData) {
      localStorage.setItem("medistreamline", JSON.stringify(socialData));
      dispatch(saveUserData(socialData));
    }
  }, []);

  return UserData?.VERIFY_ACCOUNT_IS_LOADING ? (
    <div className="d-flex justify-content-center align-items-center m-auto">
      <GenericLoader />
    </div>
  ) : (
    <>
      {isWelcome === true ? (
        <FirstThingFirst socialData={socialData} />
      ) : (
        accountCode &&
        !socialData && <VerifyEmail setIsWelcome={setIsWelcome} />
      )}
    </>
  );
};

export default Welcome;
