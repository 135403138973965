import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";

export default function DashboardRoutes() {
  const auth = JSON.parse(localStorage.getItem("med_user"));
  const RoleId = auth?.roleId;
  const location = useLocation();
  return RoleId === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTER_PATH.HOME_PAGE} state={{ from: location }} replace />
  );
}
