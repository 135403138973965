import React, { useState } from "react";
import { Box, GenericInput, Typography } from "../../Components";
import ReplyTemplate from "./components/ReplyTemplate";
import { useSelector } from "react-redux";
import MessageTemplate from "./components/MessageTemplate";
import Loader from "react-dots-loader";
import { RxCross2 } from "react-icons/rx";
import { BsEye } from "react-icons/bs";

const Chat = () => {
  const [chatArray] = useState([]);
  const chat = useSelector((state) => state.chat);
  const [previewImg] = useState(null);
  const [showEye, setShowEye] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      //   sendPrompt(textareaValue);
    }
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  return (
    <Box
      width="100%"
      height="100%"
      maxHeight="35rem"
      maxWidth="202rem"
      borderRadius="1.375rem"
      background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
      shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
      backdropFilter="blur(15px)"
      className="pt-5 mb-auto mb-5 mt-3 w-100 chat-box-b"
    >
      <div md={8} lg={12}>
        <Typography as="h4" align="center" color="rgb(147, 112, 219)">
          Patient Diagnosis
        </Typography>
      </div>
      <Box
        width="100%"
        height="calc(100vh - 28.5rem)"
        minHeight="450px"
        background="transparent"
        className="px-sm-1 px-lg-5  mt-4"
        overflowY="auto"
        id="chatBox"
      >
        {chatArray?.map((item, index) => {
          return item?.type === "reply" ? (
            <ReplyTemplate
              reply={item?.reply}
              loader={chat?.GET_WELCOME_MESSAGE_IS_LOADING}
              //   getAudio={getAudio}
              //   resetInActiveTimer={resetInActiveTimer}
              //   // speakMessage={speakMessage}
              //   isGender={state?.isGender}
            />
          ) : item?.type === "imagePrompt" ? (
            <>
              <div className="d-flex justify-content-end">
                <div className="bg-white p-3  border chat-symptoms-img">
                  <img
                    src={item.file}
                    alt={`preview-${index}`}
                    // width={300}
                    height={120}
                    className=""
                  />
                </div>
              </div>
              <MessageTemplate
                fromUser={item?.fromUser}
                message={item?.message}
              />
            </>
          ) : (
            <MessageTemplate
              fromUser={item?.fromUser}
              message={item?.message}
            />
          );
        })}
        {(chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
          chat?.GET_PROMPT_RESPONSE_IS_LOADING) && <Loader size={10} />}
      </Box>

      {/* <Box
        width="100%"
        background="var(--secondary-150, #F4FBEA)"
        borderRadius="0.75rem 0.75rem 0 0"
        className="d-flex flex-wrap justify-content-between py-3 px-sm-1 px-lg-5 "
      >
        <div className="d-flex align-items-center justify-content-center bg-red-700">
          {startListening ? (
            <Box
              width="3rem"
              height="3rem"
              // isTooltip={"Press to stop"}
              background="#ED6A58"
              className="rounded-circle d-flex justify-content-center align-items-center mx-3 cursor-pointer"
              onClick={() => {
                setStartListening(false);
                // setSentVoice(true);
                resetInActiveTimer();
                stopRecordingVoice();
              }}
            >
              <BsFillStopFill color="white" size={width > 1360 ? 24 : 15} />
            </Box>
          ) : (
            <Box
              width="3rem"
              height="3rem"
              isTooltip={videoPlaying ? false : true}
              background={videoPlaying ? "#898989" : "#53972C"}
              className={`rounded-circle d-flex justify-content-center align-items-center mx-3 mt-1 ${
                videoPlaying ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={startVoiceCommand}
            >
              <BsFillMicMuteFill
                color="#ffffff"
                size={width > 1360 ? 24 : 15}
              />
            </Box>
          )}
          {startListening && <Loader size={10} />}
          {!startListening && (
            <b>
              <small>
                If you prefer to speak to the doctor, rather than type, please
                press the mic button to speak into it.
              </small>
            </b>
          )}
        </div>
      </Box> */}
      <div className="scroll-hide">
        <div className={`upload-img ${previewImg ? "pt-3" : ""}`}>
          {previewImg && (
            <div
              className="cursor-pointer transform border transition duration-500  symptoms-img"
              onMouseEnter={() => {
                setShowEye(true);
              }}
              onMouseLeave={() => setShowEye(false)}
            >
              <img
                src={previewImg}
                alt={`previewImag`}
                height={60}
                width={100}
                className="p-2"
                // onClick={() => handlePreview()}
              />

              <RxCross2
                // onClick={() => handleImageRemove()}
                fontSize={18}
                className="remove-icon"
              />
              {showEye && (
                <div
                  className="eye-icon-overlay"
                  //   onClick={() => handlePreview(file)}
                >
                  <BsEye className=" cursor-pointer me-1" fontSize={18} />{" "}
                </div>
              )}
            </div>
          )}
          {previewImg && (
            <div className="border-bottom border-light-subtle mx-4 mt-3 " />
          )}

          <div className="input-field ">
            <GenericInput
              placeholder="Enter your message"
              className="scroll-hide-content"
              fontSize="1.25rem"
              autoFocus={true}
              width="100%"
              minHeight="100%"
              value={textareaValue}
              onChange={(e) => handleTextareaChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
              onPressEnter
              as="textarea"
              border="none"
              borderRadius="0 0 1.375rem 1.375rem"
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Chat;
