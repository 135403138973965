import React, { useEffect, useState } from "react";
import { Box, Typography } from "../../Components";

const Report = ({ checkChatReport, messagesList }) => {
  const chatReportFromAI = checkChatReport?.GENERATE_CHAT_REPORT || null;
  const chatReportFromBE = messagesList?.MESSAGE?.[0]?.report || null;

  const chatReport = chatReportFromAI || chatReportFromBE;

  console.log("messagesList", messagesList);

  const parseChatReport = (chatReport) => {
    console.log("Raw chat report", chatReport);

    if (typeof chatReport === "string") {
      try {
        let fixedChatReport = chatReport.replace(/'/g, '"');
        fixedChatReport = fixedChatReport.replace(/"s\b/g, "'s");
        return JSON.parse(fixedChatReport);
      } catch (error) {
        console.error("Error parsing JSON:", error.message);
        throw new Error("Invalid JSON format");
      }
    } else if (typeof chatReport === "object") {
      return chatReport;
    } else {
      throw new Error("Invalid chatReport format");
    }
  };

  const parsedReport = chatReport ? parseChatReport(chatReport) : null;

  const {
    "Patient Information": patientInfo,
    "Medical history": medicalHistory,
    Symptoms,
    "Instrumental Examination": instrumentalExamination,
    "Laboratory Examination": laboratoryExamination,
    "Clinical Plan": clinicalPlan,
    "Treatment Plan": treatmentPlan,
  } = parsedReport || {};

  // const {
  //   "Patient Information": { Age, Gender, Occupation } = getDefaultValues(),
  //   "Medical History": MedicalHistory = {},
  //   Symptoms = [],
  //   "Instrumental Examination": { Results } = {},
  //   "Laboratory Examination": LaboratoryExamination = {},
  //   "Clinical Plan": ClinicalPlan = getDefaultValues().ClinicalPlan,
  //   "Treatment Plan": TreatmentPlan = getDefaultValues().TreatmentPlan,
  // } = chatReport;

  const [fileUrls, setFileUrls] = useState([]);

  useEffect(() => {
    const urls = messagesList?.MESSAGE.filter((message) => message.fileUrl) // Check if fileUrl exists
      .map((message) => message.fileUrl); // Extract the fileUrl

    setFileUrls(urls); // Update the state with the file URLs
  }, [messagesList]);

  return (
    <Box
      background="transparent"
      className="py-3 overflow-auto"
      height="calc(100vh - 300px)"
      width="100%"
    >
      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="mb-2"
      >
        Documents
      </Typography>
      {fileUrls.length > 0 ? (
        <div>
          {fileUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`file-${index}`}
              style={{ maxWidth: "150px", margin: "10px", cursor: "pointer" }}
              onClick={() => {
                const newWindow = window.open();
                newWindow.document.write(
                  `<img src="${url}";"/>`
                );
              }}
            />
          ))}
        </div>
      ) : (
        <p>No images found</p>
      )}

      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="mb-2"
      >
        Patient Information
      </Typography>
      <p>{`Name: ${patientInfo?.Name || "N/A"}`}</p>
      <p>{`Age: ${patientInfo?.Age || "N/A"}`}</p>
      <p>{`Gender: ${patientInfo?.Gender || "N/A"}`}</p>
      <p>{`Occupation: ${patientInfo?.Occupation || "N/A"}`}</p>

      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="my-2"
      >
        Medical History
      </Typography>
      <p>{`Previous medical history: ${
        medicalHistory?.["Previous medical history"] || "Not available"
      }`}</p>

      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="my-2"
      >
        Symptoms
      </Typography>
      {Symptoms && Object.keys(Symptoms).length > 0 ? (
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {Object.entries(Symptoms).map(([symptom, details], idx) => (
            <li key={idx}>
              {`${symptom}: `}
              {typeof details === "object" ? (
                <ul
                  style={{
                    listStyleType: "none",
                    paddingLeft: "20px",
                    margin: 0,
                  }}
                >
                  {Object.entries(details).map(([key, value], subIdx) => (
                    <li key={subIdx}>{`${key}: ${value}`}</li>
                  ))}
                </ul>
              ) : (
                details
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No symptoms provided.</p>
      )}

      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="my-2"
      >
        Instrumental Examination
      </Typography>
      <p>{`Results: ${instrumentalExamination?.Results || "Not available"}`}</p>

      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="my-2"
      >
        Laboratory Examination
      </Typography>
      <p>{`Results: ${laboratoryExamination?.Results || "Not available"}`}</p>

      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="my-2"
      >
        Clinical Plan
      </Typography>
      <p>{clinicalPlan?.Plan || "No clinical plan provided."}</p>

      <Typography
        align="left"
        fontSize="1.5rem"
        fontWeight="500"
        className="my-2"
      >
        Treatment Plan
      </Typography>
      <p>{`Medications: ${
        treatmentPlan?.["Over-the-counter remedies"] || "Not available"
      }`}</p>
      <p>{`Other treatments: ${
        treatmentPlan?.["Other treatments"] || "Not available"
      }`}</p>
      <p>{`Follow-up: ${treatmentPlan?.["Follow-up"] || "Not available"}`}</p>
    </Box>
  );
};

export default Report;
