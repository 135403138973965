import { Box, Typography } from "../../../../Components";
import { Form } from "react-bootstrap";

const CurrentMedication = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const options = [
    {
      id: 1,
      label: "No",
    },
    {
      id: 2,
      label: "Yes",
    },
  ];
  return (
    <div>
      {" "}
      <Typography fontSize="1rem" fontWeight="400" color="#9370DB">
        MEDICAL HISTORY
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Current Medications
      </Typography>
      {options?.map((option, idx) => (
        <>
          <Box
            className="p-3 my-3 cursor-pointer option-wrapper"
            onClick={() => {
              setFieldValue("currentMedication", option?.id);
            }}
            height="100%"
            width="100%"
            borderRadius="0.5rem"
            background={values?.currentMedication === option?.id && "#9370DB"}
            border={
              values?.currentMedication === option?.id && "1px solid #9370DB"
            }
          >
            <Typography
              fontSize=".9rem"
              fontWeight={values?.currentMedication === option?.id && "500"}
              className="heading"
              color={values?.currentMedication === option?.id && "white"}
            >
              {option?.label}
            </Typography>
          </Box>
          {values?.currentMedication === 2 && option?.id === 2 && (
            <>
              <Form.Control
                type="text"
                backgroundColor="#EBEBFF"
                name="currentMedicationInput"
                placeholder={
                  "e.g 10mg of Lisinopril once daily for high blood pressure and 500mg of Metformin twice a day for diabetes."
                }
                className="w-100 fs-6"
                value={values?.currentMedicationInput}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  touched.currentMedicationInput &&
                  !!errors.currentMedicationInput
                }
                size="lg"
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.currentMedicationInput}
              </Form.Control.Feedback>
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default CurrentMedication;
