/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Box,
  Button,
  GenericLoader,
  GenericModal,
  Toaster,
  Typography,
} from "../../Components";
import { FaRegCheckCircle, FaArrowLeft } from "react-icons/fa";
import { ROUTER_PATH, stripePromiseKey } from "../../config";
import { useNavigate } from "react-router-dom";
import StripeModal from "./StripeModal";
import {
  cancelSubscriptions,
  getAllUserPlans,
} from "../../Redux/actions/subscriptionAction";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../../utils/helper";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { IoClose } from "react-icons/io5";

const Subscription = () => {
  const user = getLoggedInUser();
  const [duration, setDuration] = useState("Monthly");

  const [planId, setPlanId] = useState(user?.planId);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [isCancelModal, setCancelModal] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);

  useEffect(() => {
    dispatch(getAllUserPlans());
    // dispatch(getAllUserPlans(user?.roleId));
  }, [dispatch]);

  const subscription = useSelector((state) => state.subscription);

  console.log("user", planId, user, subscription);
  const { width } = useWindowSizeHook();
  // const checkSubscription = (response) => {
  //   if (!response?.data) {
  //     Toaster({
  //       message: "Subscription failed, try agian later",
  //       type: "error",
  //     });
  //   } else {
  //     Toaster({
  //       message: response?.message,
  //       type: "success",
  //     });
  //   }
  // };

  const moveToNext = (response) => {
    if (response?.data?.succeeded) {
      Toaster({
        message: response.data.message,
        type: "success",
      });
      setIsVisible(false);
      
      localStorage.setItem(
        "med_user",
        JSON.stringify({
          ...user,
          planId: response?.data.data === false ? 1 : planId,
          currentPlanId: response?.data.data === false ? 1 : planId,
          isSubscription: false,
        })
      );
      setPlanId(response?.data.data === false ? 1 : planId);
    } else {
      Toaster({
        message: response.data.message,
        type: "error",
      });
    }
  };

  // const handleFreeSubscription = (id) => {
  //   if (id !== 1) {
  //     setCancelModal(true);
  //   } else dispatch(cancelSubscriptions(user?.userId, moveToNext));
  // };

  const handleCancelSubscription = () => {
    dispatch(cancelSubscriptions(user?.userId, moveToNext));
    setCancelModal(false);
    // setPlanId(null);
  };

  useEffect(() => {
    const filteredPlans = subscription?.GET_ALL_PLANS?.filter((item) => {
      if (duration === "Monthly") {
        return item.planId === 1 || item.planId === 2 || item.planId === 3;
      } else if (duration === "Yearly") {
        return item.planId === 1 || item.planId === 4 || item?.planId === 5;
      }
      return false;
    });
    setFilteredPlans(filteredPlans);
  }, [duration, subscription]);

  function changeSubscriptionPlan(id) {
    if ((user?.planId === id || id === 1)) {
      setCancelModal(true);
    } else setIsVisible(true);
  }
  return (
    <Box
      width="100%"
      maxWidth="202rem"
      borderRadius="1.375rem"
      background="white"
      shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
      backdropFilter="blur(15px)"
      className="p-4 mt-3 m-auto position-relative"
    >
      <div className="upper-element d-none d-lg-block" />
      <div className="lower-element d-none d-lg-block" />
      <span
        className="d-flex align-items-center fw-bold text-primary cursor-pointer"
        onClick={() => navigate(ROUTER_PATH.HOME_PAGE)}
      >
        <FaArrowLeft className="me-2" /> Back
      </span>
      <Typography
        as="h4"
        align="center"
        fontWeight="300"
        color="black"
        className="my-3"
      >
        Choose Your Plan
      </Typography>
      <Box
        background="#D3E7F0"
        borderRadius="0.25rem"
        className="d-flex m-auto  mb-3"
      >
        <div
          className={`p-2 px-5 cursor-pointer ${duration === "Monthly" ? "active-plan" : ""
            }`}
          onClick={() => setDuration("Monthly")}
        >
          <Typography
            fontSize="0.9rem"
            fontWeight="600"
            align="center"
            color={duration === "Monthly" ? "#fff" : "#4F2883"}
          >
            Monthly
          </Typography>
        </div>
        <div
          className={`p-2 px-5 cursor-pointer ${duration === "Yearly" ? "active-plan" : ""
            }`}
          onClick={() => setDuration("Yearly")}
        >
          <Typography
            fontSize="0.9rem"
            fontWeight="600"
            align="center"
            color={duration === "Yearly" ? "#fff" : "#4F2883"}
          // color="#4F2883"
          >
            Yearly
          </Typography>
        </div>
      </Box>
      <div className="d-flex align-items-center justify-content-center">
        <Typography
          align="center"
          fontWeight="400"
          color="Black"
          fontSize="1.25rem"
          className="me-2"
        >
          Pricing (US Dollar)
        </Typography>
        {/* <Typography
          align="center"
          fontWeight="400"
          color="#034F75"
          fontSize="1.25rem"
        >
          Office Management
        </Typography> */}
      </div>
      <Row className="d-flex align-items-end justify-content-center">
        {filteredPlans?.map((subscription, idx) => (
          // {SUBSCRIPTION_PLAN?.filter(
          //   (item) => item?.type === duration && item?.roleId === user?.roleId
          // )?.map((subscription, idx) => (
          <Col
            xxl={width > 1550 ? 3 : 4}
            xl={4}
            lg={5}
            md={9}
            sm={9}
            className="my-3 cursor-pointer"
            onClick={() => setPlanId(subscription?.planId)}
          >
            <Box
              minHeight="600px"
              width="100%"
              border="0.65px solid #9370DB"
              background={planId === subscription?.planId ? "#9370DB" : "#fff"}
              borderRadius="0.5rem"
              className="p-3 d-flex flex-column justify-content-between"
            >
              <div>
                <div className="d-flex justify-content-center">
                  <Box
                    border="0.65px solid white"
                    background="#fff"
                    borderRadius="0.5rem"
                    // className="px-3 p-1"
                    className={`px-3 my-1 p-1 text-primary `}
                  >
                    {subscription?.name === "Free"
                      ? "Basic"
                      : subscription?.name}
                  </Box>
                </div>
                <Typography
                  align="center"
                  fontWeight="700"
                  color={
                    planId === subscription?.planId
                      ? "#fff"
                      : "rgb(147, 112, 219)"
                  }
                  fontSize="2.25rem"
                  className="my-2"
                >
                  {`${subscription?.planId !== 1 ? "$" : ""}` +
                    `${subscription?.price === 0 ? "Free" : subscription?.price
                    }`}
                </Typography>
                {/* <Typography
                  align="center"
                  fontWeight="400"
                  color="#81A7BA"
                  fontSize="0.85rem"
                >
                  User/Month
                </Typography> */}
                <div className="feature-list my-3">
                  {subscription?.descriptions?.map((item, idx) => (
                    <div key={idx} className="d-flex align-items-start my-1">
                      <FaRegCheckCircle
                        size={24}
                        className="w-10 text-green me-3"
                      />
                      <Typography
                        align="left"
                        fontWeight="400"
                        // color={planId === "basic" ? "#81A7BA" : "#fff"}
                        fontSize="0.75rem"
                        className={`me-2 w-90 ${planId === subscription?.planId
                            ? "text-white"
                            : "text-black"
                          }`}
                      >
                        {item}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
              <Button
                className="w-100 my-2"
                // type="submit"
                backgroundColor={
                  planId === subscription?.planId ? "#fff" : "#9370DB"
                }
                disabled={
                  user?.planId === subscription?.planId && user?.planId === 1
                }
                onClick={() => {
                  // subscription?.planId === 1
                  //  handleFreeSubscription(subscription?.planId)
                  changeSubscriptionPlan(subscription?.planId);
                  // : setIsVisible(true);
                }}
              >
                <Typography
                  align="center"
                  fontWeight="500"
                  fontSize="1rem"
                  color={planId === subscription?.planId ? "#9370DB" : "#fff"}
                >
                  {subscription?.CREATE_SUBSCRIPTION_IS_LOADING ? (
                    <GenericLoader />
                  ) : user?.planId === subscription?.planId &&
                    user?.isSubscription !== false ? (
                    user?.planId === 1 ? (
                      "Selected Plan"
                    ) : (
                      "Cancel Subscription"
                    )
                  ) : (
                    "Choose Plan"
                  )}
                </Typography>
              </Button>
            </Box>
          </Col>
        ))}
      </Row>
      {isVisible && (
        <StripeModal
          planId={planId}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          stripePromiseKey={stripePromiseKey}
        />
      )}
      {isCancelModal && (
        <GenericModal
          show={isCancelModal}
          onHide={() => setCancelModal(false)}
          className="p-5  "
          centered
          aria-labelledby="modal"
        >
          <div className="p-4  ">
            <div className="d-flex justify-content-end w-100">
              <IoClose
                size={20}
                onClick={() => setCancelModal(false)}
                className="cursor-pointer"
              />
            </div>
            <Typography
              align="center"
              fontSize={width < 500 ? "1rem" : "1.2rem"}
              fontWeight="500"
              className="p-2"
            >
              {planId === 1
                ? "Are You Sure Want To Go With Free Version"
                : "Are You Sure Want To Cancel This Subscription"}
            </Typography>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <Button
                className="bg-secondary"
                minWidth={width < 500 ? "6rem" : "8rem"}
                height={width < 500 ? "2.5rem" : "3rem"}
                onClick={() => setCancelModal(false)}
              >
                <Typography
                  align="center"
                  fontSize="1rem"
                  fontWeight="500"
                  color="#fff"
                >
                  No
                </Typography>
              </Button>
              <Button
                minWidth={width < 500 ? "6rem" : "8rem"}
                height={width < 500 ? "2.5rem" : "3rem"}
                onClick={() => handleCancelSubscription()}
              >
                <Typography
                  align="center"
                  fontSize="1rem"
                  fontWeight="500"
                  color="#fff"
                >
                  Yes
                </Typography>
              </Button>
            </div>
          </div>
        </GenericModal>
      )}
    </Box>
  );
};

export default Subscription;
