const INITIAL_STATES = {
  GET_BLOOD_PRESSURE_IS_LOADING: false,
  GET_BLOOD_PRESSURE_IS_SUCCESS: false,
  GET_BLOOD_PRESSURE_IS_FAILURE: false,
  BLOOD_PRESSURE_DATA: [],
  GET_BLOOD_PRESSURE_ERROR: null,

  GET_PULSE_RATE_IS_LOADING: false,
  GET_PULSE_RATE_IS_SUCCESS: false,
  GET_PULSE_RATE_IS_FAILURE: false,
  PULSE_RATE_DATA: [],
  GET_PULSE_RATE_ERROR: null,

  GET_TEMPERATURE_IS_LOADING: false,
  GET_TEMPERATURE_IS_SUCCESS: false,
  GET_TEMPERATURE_IS_FAILURE: false,
  TEMPERATURE_DATA: [],
  GET_TEMPERATURE_ERROR: null,

  GET_GLUCOSE_LEVEL_IS_LOADING: false,
  GET_GLUCOSE_LEVEL_IS_SUCCESS: false,
  GET_GLUCOSE_LEVEL_IS_FAILURE: false,
  GLUCOSE_LEVEL_DATA: [],
  GET_GLOCUSE_LEVEL_ERROR: null,

  GET_BLOOD_OXYGEN_IS_LOADING: false,
  GET_BLOOD_OXYGEN_IS_SUCCESS: false,
  GET_BLOOD_OXYGEN_IS_FAILURE: false,
  BLOOD_OXYGEN_DATA: [],
  GET_BLOOD_OXYGEN_ERROR: null,

  GET_WEIGHT_IS_LOADING: false,
  GET_WEIGHT_IS_SUCCESS: false,
  GET_WEIGHT_IS_FAILURE: false,
  WEIGHT_DATA: [],
  GET_WEIGHT_ERROR: null,
}

export function vitalMonitoringReducer(state=INITIAL_STATES, action){
  switch (action.type) {
    case "GET_BLOOD_PRESSURE_REQUEST":
      return{
        ...state,
        GET_BLOOD_PRESSURE_IS_LOADING : true ,
        GET_BLOOD_PRESSURE_IS_SUCCESS :false,
        GET_BLOOD_PRESSURE_IS_FAILURE :false,
        BLOOD_PRESSURE_DATA: [],
        GET_BLOOD_PRESSURE_ERROR:null,
      }
    case "GET_BLOOD_PRESSURE_SUCCESS":
      return{
        ...state,
        GET_BLOOD_PRESSURE_IS_LOADING : false,
        GET_BLOOD_PRESSURE_IS_SUCCESS : true,
        GET_BLOOD_PRESSURE_IS_FAILURE : false,
        BLOOD_PRESSURE_DATA: action.payload,
        GET_BLOOD_PRESSURE_ERROR:null,
      }
    case "GET_BLOOD_PRESSURE_FAILURE":
      return{
        ...state,
        GET_BLOOD_PRESSURE_IS_LOADING : false,
        GET_BLOOD_PRESSURE_IS_SUCCESS : false,
        GET_BLOOD_PRESSURE_IS_FAILURE : true,
        BLOOD_PRESSURE_DATA:[],
        GET_BLOOD_PRESSURE_ERROR:action.payload.message,
      }

    case "GET_PULSE_RATE_REQUEST":
      return{
        ...state,
        GET_PULSE_RATE_IS_LOADING :true,
        GET_PULSE_RATE_IS_SUCCESS :false,
        GET_PULSE_RATE_IS_FAILURE :false,
        PULSE_RATE_DATA: [],
        GET_PULSE_RATE_ERROR:null,
      }
    case "GET_PULSE_RATE_SUCCESS":
      return{
        ...state,
        GET_PULSE_RATE_IS_LOADING: false,
        GET_PULSE_RATE_IS_SUCCESS: true,
        GET_PULSE_RATE_IS_FAILURE: false,
        PULSE_RATE_DATA: action.payload,
        GET_PULSE_RATE_ERROR:null,
      }
    case "GET_PULSE_RATE_FAILURE":
      return{
        ...state,
        GET_PULSE_RATE_IS_LOADING: false,
        GET_PULSE_RATE_IS_SUCCESS: false,
        GET_PULSE_RATE_IS_FAILURE: true,
        PULSE_RATE_DATA:[],
        GET_PULSE_RATE_ERROR:action.payload.message,
      }

    case "GET_TEMPERATURE_REQUEST":
      return{
        ...state,
        GET_TEMPERATURE_IS_LOADING :true,
        GET_TEMPERATURE_IS_SUCCESS :false,
        GET_TEMPERATURE_IS_FAILURE :false,
        TEMPERATURE_DATA: [],
        GET_TEMPERATURE_ERROR:null,
      }
    case "GET_TEMPERATURE_IS_SUCCESS":
      return{
        ...state,
        GET_TEMPERATURE_IS_LOADING: false,
        GET_TEMPERATURE_IS_SUCCESS: true,
        GET_TEMPERATURE_IS_FAILURE: false,
        TEMPERATURE_DATA: action.payload,
        GET_TEMPERATURE_ERROR: null,
      }
    case "GET_TEMPERATURE_IS_FAILURE":
      return{
        ...state,
        GET_TEMPERATURE_IS_LOADING: false,
        GET_TEMPERATURE_IS_SUCCESS: false,
        GET_TEMPERATURE_IS_FAILURE: true,
        TEMPERATURE_DATA:[],
        GET_TEMPERATURE_ERROR:action.payload.message,
      }

    case "GET_GLUCOSE_LEVEL_REQUEST":
      return{
        ...state,
        GET_GLUCOSE_LEVEL_IS_LOADING: true,
        GET_GLUCOSE_LEVEL_IS_SUCCESS: false,
        GET_GLUCOSE_LEVEL_IS_FAILURE: false,
        GLUCOSE_LEVEL_DATA:[],
        GET_GLUCOSE_LEVEL_ERROR:null,
      }
    case "GET_GLUCOSE_LEVEL_SUCCESS":
      return{
        ...state,
        GET_GLUCOSE_LEVEL_IS_LOADING: false,
        GET_GLUCOSE_LEVEL_IS_SUCCESS: true,
        GET_GLUCOSE_LEVEL_IS_FAILURE: false,
        GLUCOSE_LEVEL_DATA: action.payload,
        GET_GLUCOSE_LEVEL_ERROR: null,
      }
    case "GET_GLUCOSE_LEVEL_FAILURE":
      return{
        ...state,
        GET_GLUCOSE_LEVEL_IS_LOADING: false,
        GET_GLUCOSE_LEVEL_IS_SUCCESS: false,
        GET_GLUCOSE_LEVEL_IS_FAILURE: true,
        GLUCOSE_LEVEL_DATA:[],
        GET_GLUCOSE_LEVEL_ERROR:action.payload.message,
      }

    case "GET_BLOOD_OXYGEN_REQUEST":
      return{
        ...state,
        GET_BLOOD_OXYGEN_IS_LOADING:true,
        GET_BLOOD_OXYGEN_IS_SUCCESS:false,
        GET_BLOOD_OXYGEN_IS_FAILURE:false,
        BLOOD_OXYGEN_DATA: [],
        GET_BLOOD_OXYGEN_ERROR : null
      }
    case "GET_BLOOD_OXYGEN_SUCCESS":
      return{
        ...state,
        GET_BLOOD_OXYGEN_IS_LOADING:false,
        GET_BLOOD_OXYGEN_IS_SUCCESS:true,
        GET_BLOOD_OXYGEN_IS_FAILURE:false,
        BLOOD_OXYGEN_DATA:action.payload,
        GET_BLOOD_OXYGEN_ERROR:null
      }
    case "GET_BLOOD_OXYGEN_FAILURE":
      return{
        ...state,
        GET_BLOOD_OXYGEN_IS_LOADING:false,
        GET_BLOOD_OXYGEN_IS_SUCCESS:false,
        GET_BLOOD_OXYGEN_IS_FAILURE:true,
        BLOOD_OXYGEN_DATA:[],
        GET_BLOOD_OXYGEN_ERROR:action.payload.message
      }

    case "GET_WEIGHT_REQUEST":
      return{
        ...state,
        GET_WEIGHT_IS_LOADING:true,
        GET_WEIGHT_IS_SUCCESS:false,
        GET_WEIGHT_IS_FAILURE:false,
        WEIGHT_DATA: [],
        GET_WEIGHT_ERROR: null,
      }
    case "GET_WEIGHT_SUCCESS":
      return{
        ...state,
        GET_WEIGHT_IS_LOADING: false,
        GET_WEIGHT_IS_SUCCESS: true,
        GET_WEIGHT_IS_FAILURE: false,
        WEIGHT_DATA: action.payload,
        GET_WEIGHT_ERROR: null
      }
    case "GET_WEIGHT_FAILURE":
      return{
        ...state,
        GET_WEIGHT_IS_LOADING:false,
        GET_WEIGHT_IS_SUCCESS:false,
        GET_WEIGHT_IS_FAILURE:true,
        WEIGHT_DATA:[],
        GET_WEIGHT_ERROR:action.payload.message,
      }
  
    default:
      return state
  }
}