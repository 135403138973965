import axios from "axios";
import { API_URLS } from "../../config";

function getUserIdAPI() {
  return axios.get(`${API_URLS.USER.GENERATE_ID}`);
}

function signUpAPI(data) {
  return axios.post(`${API_URLS.USER.SIGN_UP}`, data);
}

function signInAPI(data) {
  return axios.post(`${API_URLS.USER.SIGN_IN}`, data);
}
function socialLogInAPI(data) {
  return axios.post(`${API_URLS.USER.SOCIAL_MEDIA_LOGIN}`, data);
}
function verifyAccountApi({accountCode}) {
  return axios.patch(`${API_URLS.USER.VERIFY_ACCOUNT}/${accountCode}`);
}
function sendOTPApi(data) {
  return axios.post(`${API_URLS.AUTH.SEND_OTP}`, data);
}
function verifyOTPApi(data) {
  return axios.post(`${API_URLS.AUTH.VERIFY_OTP}`, data);
}
function resetPasswordApi(data) {
  return axios.post(`${API_URLS.AUTH.RESET_PASSWORD}`, data);
}

export { getUserIdAPI, signUpAPI, signInAPI ,socialLogInAPI ,verifyAccountApi ,resetPasswordApi,verifyOTPApi,sendOTPApi};
