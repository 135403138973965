import React, { useState } from "react";
import {
  Button,
  GenericLoader,
  InputField,
  Typography,
} from "../../../Components";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import { InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../../config";
import { useFormik } from "formik";
import { resetPasswordAction } from "../../../Redux/actions";
import { MdLockOutline } from "react-icons/md";
import { FiEye, FiEyeOff } from "react-icons/fi";

function ResetPassword() {
  const user = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const PASSWORD_SCHEMA = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Please enter your new password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your new password"),
  });
  const moveToNext = (response) => {
    response?.data?.succeeded && navigate(ROUTER_PATH.SIGN_IN);
    // : navigate(ROUTER_PATH.FORGOT_PASSWORD);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: PASSWORD_SCHEMA,
    onSubmit: (values) => {
      dispatch(
        resetPasswordAction(
          {
            email: location?.state,
            password: values.password,
          },
          moveToNext
        )
      );
    },
  });

  return (
    <div>
      <div>
        <div className={`h-screen`}>
          <Row className="m-0 p-5 vh-100  w-100 align-items-center justify-content-end">
            <Col
              md={12}
              lg={4}
              className="m-0 p-0 d-flex align-items-start flex-column"
            >
              <h1 className="text-white  font-poppins-bold">Change Password</h1>

              <form
                onSubmit={formik.handleSubmit}
                className="container-fluid rounded-1 p-0"
              >
                <Row className="m-0 p-0">
                  <Col md={12} className="mt-4 p-0">
                    <p className="text-white font-poppins-semibold mb-0">
                      Enter New Password
                    </p>
                    <InputField
                      backgroundColor="#EBEBFF"
                      type={!showPassword ? "password" : "text"}
                      name="password"
                      placeholder="New Password"
                      className="w-100"
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <MdLockOutline
                            size={20}
                            className="text-light-gray"
                          />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="center" className="ps-2  ">
                          {!showPassword ? (
                            <FiEyeOff
                              size={20}
                              className=" text-light-gray cursor-pointer"
                              onClick={() => setShowPassword(true)}
                            />
                          ) : (
                            <FiEye
                              size={20}
                              className=" text-light-gray cursor-pointer"
                              onClick={() => setShowPassword(false)}
                            />
                          )}
                        </InputAdornment>
                      }
                    />
                    {formik.touched.password && formik.errors.password && (
                      <small className="text-danger small">
                        {formik.errors.password}
                      </small>
                    )}
                  </Col>
                  <Col md={12} className="mt-2 p-0">
                    <p className="text-white font-poppins-semibold mb-0">
                      Enter Confirm Password
                    </p>
                    <InputField
                      backgroundColor="#EBEBFF"
                      type={!showPassword ? "password" : "text"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className="w-100"
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <MdLockOutline
                            size={20}
                            className="text-light-gray"
                          />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="center" className="ps-2  ">
                          {!showPassword ? (
                            <FiEyeOff
                              size={20}
                              className=" text-light-gray cursor-pointer"
                              onClick={() => setShowPassword(true)}
                            />
                          ) : (
                            <FiEye
                              size={20}
                              className=" text-light-gray cursor-pointer"
                              onClick={() => setShowPassword(false)}
                            />
                          )}
                        </InputAdornment>
                      }
                    />
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <small className="text-danger small">
                          {formik.errors.confirmPassword}
                        </small>
                      )}
                  </Col>

                  <Col md={12} className="m-0 p-0 mt-4">
                    <Button type="submit" className="w-100 signup-button">
                      <Typography
                        fontWeight="500"
                        align="center"
                        fontSize="1rem"
                        color="#fff"
                      >
                        {user?.RESET_PASSWORD_IS_LOADING ? (
                          <GenericLoader />
                        ) : (
                          " Submit"
                        )}
                      </Typography>
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
