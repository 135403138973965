import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, GenericModal, Typography } from "../../Components";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { ROUTER_PATH } from "../../config";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

// const consultantSpecializationList = [
//   {
//     title: "Physician",
//     key: "physician",
//   },
//   {
//     title: "Gastroenterologist",
//     key: "gastroenterologist",
//   },
//   {
//     title: "Pulmonologist ",
//     key: "pulmonologist ",
//   },
//   {
//     title: "Urologist",
//     key: "urologist",
//   },
//   {
//     title: "Orthopedic",
//     key: "orthopedic",
//   },
// ];

export default function SelectLanguage({ show, onHide, gender, vitals }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  //   const [selectedSpecialization, setSelectedSpecialization] = useState("");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  //   const handleSpecializationChange = (event) => {
  //     setSelectedSpecialization(event.target.value);
  //   };

  const { width } = useWindowSizeHook();
  const navigate = useNavigate();

  const handleChatLanguage = () => {
    if (!selectedLanguage) {
      return toast.error("Please select language");
    }

    // if (!selectedSpecialization) {
    //   return toast.error("Please select specialization");
    // }

    // if (chat?.CHAT?.length > 0)
    navigate(ROUTER_PATH.PATIENT_DIAGNOSIS, {
      state: {
        gender: gender,
        language: selectedLanguage,
        vitals: vitals,
        //   specialization: selectedSpecialization,
      },
    });
    // else
    //   navigate(ROUTER_PATH.PATIENT_DIAGNOSIS, {
    //     state: {
    //       gender: gender === "Male" ? "male" : "female",
    //       language: selectedLanguage,
    //       specialization: selectedSpecialization,
    //     },
    //   });
  };
  return (
    <GenericModal
      show={show}
      onHide={onHide}
      closeModal
      size="lg"
      hideFooter={true}
      className="d-flex justify-content-center align-items-center custom-modal-style "
    >
      <div className="flex justify-items-center  w-100 px-2 px-md-5 p-4 mb-4 ">
        <Typography
          align="center"
          fontSize={width < 500 ? "1rem" : "1.2rem"}
          fontWeight="500"
          className="p-3 fw-bold"
          color="rgb(147, 112, 219)"
        >
          Choose Your Preferred Language
        </Typography>
        <div>
          <Form.Group className="mb-4">
            {/* <Form.Label className="fw-bold mb-2">Select Language</Form.Label> */}
            <Form.Select
              aria-placeholder="Select"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              <option value="">Select</option>
              <option value="en">English</option>
              <option value="ar">Arabic</option>
              <option value="de">German </option>
              <option value="fr">French</option>
            </Form.Select>
          </Form.Group>

          {/* <Form.Group className="mb-4">
            <Form.Label className="fw-bold mb-2">
              Select Specialization
            </Form.Label>
            <Form.Select
              aria-placeholder="Select"
              value={selectedSpecialization}
              onChange={handleSpecializationChange}
            >
              <option value="">Select</option>
              {consultantSpecializationList?.map((item, index) => (
                <option key={index} value={item?.key}>
                  {item?.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group> */}
        </div>
        <div className="d-flex gap-3  justify-content-center align-items-center">
          <Button
            minWidth={width < 500 ? "6rem" : "8rem"}
            height={width < 500 ? "2.5rem" : "3rem"}
            onClick={() => {
              handleChatLanguage();
            }}
            className="btn-color"
          >
            <Typography
              align="center"
              fontSize="1rem"
              fontWeight="500"
              color="white"
            >
              Continue
            </Typography>
          </Button>
        </div>
        {/* <Button
          className="bg-secondary"
          minWidth={width < 500 ? "6rem" : "8rem"}
          height={width < 500 ? "2.5rem" : "3rem"}
          onClick={() => {
            handleChatLanguage();
          }}
        >
          <Typography
            align="center"
            fontSize="1rem"
            fontWeight="500"
            color="white"
          >
            Continue
          </Typography>
        </Button> */}

        {/* <div className="d-flex gap-3  justify-content-center align-items-center">
          <Button
            className="bg-secondary"
            minWidth={width < 500 ? "6rem" : "8rem"}
            height={width < 500 ? "2.5rem" : "3rem"}
            onClick={() => {
              handleChatLanguage("en");
            }}
          >
            <Typography
              align="center"
              fontSize="1rem"
              fontWeight="500"
              color="white"
            >
              English
            </Typography>
          </Button>
          <Button
            minWidth={width < 500 ? "6rem" : "8rem"}
            height={width < 500 ? "2.5rem" : "3rem"}
            onClick={() => {
              handleChatLanguage("ar");
            }}
            className="btn-color"
          >
            <Typography
              align="center"
              fontSize="1rem"
              fontWeight="500"
              color="white"
            >
              Arabic
            </Typography>
          </Button>
        </div> */}
      </div>
    </GenericModal>
  );
}
